import {Injectable} from '@angular/core';
import {PersonCtrl} from '../../core/sdk/bighero-controllers';
import {Person, PersonJournalEntry, Security} from '../../core/sdk/bighero-model';
import {PersonRole} from '../../core/sdk/model-roles';
import {Utility} from '../utilities/utility';
import {Observable} from 'rxjs';
import {PersonLogin, SearchCriteriaForClass} from '../../core/sdk/model-dto';
import {PersonRoleEnum} from '../../core/sdk/model-enums';
import {Permission} from '../../core/sdk/model-permission';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  public usersData: Person[];

  constructor(private personCtrl: PersonCtrl) {
  }

  public getNewUser(defaultLanguage?: string,
                    email?: string,
                    journalEntryList?: PersonJournalEntry[],
                    name?: string,
                    permissionList?: Permission[],
                    personRoleList?: PersonRole[],
                    security?: Security,
                    telephone?: string): Person {
    return {
      id: 'person/' + Utility.getUUID(),
      timestamp: 0,
      version: 0,
      avatar: null,
      defaultLanguage,
      email,
      journalEntryList,
      name,
      telephone,
      permissionList,
      personRoleList,
      security
    };
  }

  public setUserData(data: Person[]): void {
    this.usersData = data;
  }

  public getUserData(): Person[] {
    return this.usersData;
  }

  public createUser(person: Person, personRole: PersonRoleEnum): Observable<Person> {
    return this.personCtrl.createPerson(person, personRole);
  }

  public getAllUsers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]> {
    return this.personCtrl.getAllPeople(searchCriteria, pageNumber, pageSize);
  }

  public countAllUsers(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.personCtrl.countAllPeople(searchCriteria);
  }

  public getAllSalesManager(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]> {
    return this.personCtrl.getAllSalesManagers(searchCriteria, pageNumber, pageSize);
  }

  public countAllSalesManagers(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.personCtrl.countAllSalesManagers(searchCriteria);
  }

  public addNewRole(personId: string, selectedRoleEnum: PersonRoleEnum): Observable<Person> {
    return this.personCtrl.addPersonRole(personId, selectedRoleEnum);
  }

  public updatePersonRole(personId: string, personRoleEnum: number, isActive: boolean): Observable<Person> {
    return this.personCtrl.updatePersonRole(personId, personRoleEnum, isActive);
  }

  public convertPersonRoleToEnum(personRole: PersonRole): number {
    return PersonRoleEnum[personRole.id.split('/')[0].toUpperCase().split('-').join('_')];
  }

  public getUser(userId: string): Observable<Person> {
    return this.personCtrl.getObject(Utility.getObjectId(userId));
  }

  public countWorkers(searchCriteria: SearchCriteriaForClass): Observable<number> {
    return this.personCtrl.countWorkers(searchCriteria);
  }

  public getWorkers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]> {
    return this.personCtrl.getAllWorkers(searchCriteria, pageNumber, pageSize);
  }

  public countGroupLeaders(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.personCtrl.countGroupLeaders(searchCriteriaForClass);
  }

  public getGroupLeaders(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]> {
    return this.personCtrl.getAllGroupLeaders(searchCriteria, pageNumber, pageSize);
  }

  public countInHouseManagers(searchCriteriaForClass: SearchCriteriaForClass): Observable<number> {
    return this.personCtrl.countInHouseManagers(searchCriteriaForClass);
  }

  public getInHouseManagers(searchCriteria: SearchCriteriaForClass, pageNumber: number, pageSize: number): Observable<Person[]> {
    return this.personCtrl.getInHouseManagers(searchCriteria, pageNumber, pageSize);
  }

  public createQRLoginCode(personLogin: PersonLogin): Observable<string> {
    return this.personCtrl.createQRLoginCode(personLogin);
  }

}
